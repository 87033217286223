import { useEffect, useState } from 'react';
import { PromptBucketedMetric, PromptMetricOverview, PromptMetricTokenDetails } from '../../types';
import { formatCurrency, getErrorMessage } from '../../common/utils';
import { PromptVersionSelector, MetricOverviewGroup } from '../';
import toast from 'react-hot-toast';
import { differenceInHours, format, subDays } from 'date-fns';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  getPromptBucketedMetrics,
  getPromptBucketedModelMetrics,
  getPromptsBucketedMetrics,
  getPromptsBucketedModelMetrics,
  getPromptVersionBucketedMetrics,
  getPromptVersionBucketedModelMetrics
} from '../../services/Analytics';
import { PV } from '../common/PromptVersionSelector';
import { CHART_COLORS } from '../../constants';
import Skeleton from 'react-loading-skeleton';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { ModelBucketedMetric } from '../../types/Metrics';
import { isAdmin } from '../../services/User';

interface Props {}

const labelSizeSmall = { fontSize: '0.85em' };
const chartContainerHeight = 300;
const chartSkeletons = 11;

/**
 * Prompt Analytics page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const AnalyticsTab: React.FC<Props> = ({}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPV, setSelectedPV] = useState<PV>();
  const [dateRange, setDateRange] = useState<DateValueType>({ startDate: subDays(new Date(), 7), endDate: new Date() });
  const [modelUsage, setModelUsage] = useState<PromptMetricTokenDetails[]>();
  const [bucketedMetrics, setBucketedMetrics] = useState<PromptBucketedMetric[]>();
  const [bucketedModelMetrics, setBucketedModelMetrics] = useState<Record<string, ModelBucketedMetric[]>>();
  const [modelsUsed, setModelsUsed] = useState<string[]>();
  const [modelMetrics, setModelMetrics] = useState<Array<Record<string, number | string>>>();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [adminShowAll, setAdminShowAll] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await loadGraphData();
    })();
  }, [selectedPV, dateRange, includeArchived, adminShowAll]);

  const loadGraphData = async () => {
    setIsLoading(true);

    const startDate = dateRange?.startDate as Date;
    const endDate = dateRange?.endDate as Date;

    let fnModel: Function;
    let fn: Function;
    let args: (string | number | Date)[] = [];
    let archiveFlag = true;

    if (selectedPV?.version && selectedPV?.version.version) {
      fn = getPromptVersionBucketedMetrics;
      fnModel = getPromptVersionBucketedModelMetrics;
      args = [selectedPV.prompt!.id!, selectedPV.version.version, startDate, endDate];
    } else if (selectedPV?.prompt && selectedPV?.prompt.id) {
      fn = getPromptBucketedMetrics;
      fnModel = getPromptBucketedModelMetrics;
      args = [selectedPV.prompt.id, startDate, endDate];
    } else {
      fn = getPromptsBucketedMetrics;
      fnModel = getPromptsBucketedModelMetrics;
      archiveFlag = includeArchived;
      args = [];
    }

    let _bucketedMetrics: PromptBucketedMetric[];
    let _modelMetrics: Record<string, ModelBucketedMetric[]>;

    try {
      [_bucketedMetrics, _modelMetrics] = await Promise.all([
        fn(...args, startDate, endDate, archiveFlag, adminShowAll),
        fnModel(...args, startDate, endDate, archiveFlag, adminShowAll)
      ]);

      setBucketedMetrics(_bucketedMetrics);
      setBucketedModelMetrics(_modelMetrics);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!modelsUsed || !bucketedModelMetrics) return;

    const modelUsageGraphData = Object.keys(bucketedModelMetrics).map((d) => {
      const tmp: any = {};
      modelsUsed.forEach((model) => {
        tmp[model] = 0;
        bucketedModelMetrics[d].forEach((m) => {
          if (m.model === model) {
            tmp[model] = m.totalCalls;
          }
        });
      });
      return { date: d, total: bucketedModelMetrics[d].reduce((acc, m) => acc + m.totalCalls, 0), ...tmp };
    });

    setModelMetrics(modelUsageGraphData);
  }, [modelsUsed, bucketedModelMetrics]);

  const formatDateLabel = (date: Date): string => {
    const pattern = differenceInHours(dateRange?.endDate as Date, dateRange?.startDate as Date) > 48 ? 'M/d' : 'h a';
    return format(new Date(date), pattern);
  };

  const handleOverviewDataChange = (data: PromptMetricOverview) => {
    try {
      setModelUsage(data.tokensByModel);
      setModelsUsed(data.tokensByModel.map((d) => d.model));
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <>
      <div className="mx-auto">
        <div className="flex mb-10">
          <PromptVersionSelector onChange={setSelectedPV} disabled={isLoading} className="flex-1" />
          <div className="w-56 mr-4">
            <Datepicker
              value={dateRange}
              onChange={(v) => setDateRange(v)}
              primaryColor="indigo"
              showShortcuts={true}
              showFooter={true}
              displayFormat={'MM/DD/YY'}
              disabled={isLoading}
            />
          </div>
          <div className="flex items-center">
            <div className="text-xs text-gray-500 mr-1 pt-0.5">Archived</div>
            <input
              type="checkbox"
              checked={includeArchived}
              className="peer sr-only opacity-0"
              id="showArchived"
              disabled={isLoading}
              onChange={() => setIncludeArchived(!includeArchived)}
            />
            <label htmlFor="showArchived" className="selector mt-1" />
          </div>
          {isAdmin() && (
            <div className="flex items-center ml-4">
              <div className="text-xs text-gray-500 mr-1 pt-0.5">All Prompts</div>
              <input
                type="checkbox"
                checked={adminShowAll}
                className="peer sr-only opacity-0"
                id="showAdminAll"
                disabled={isLoading}
                onChange={() => setAdminShowAll(!adminShowAll)}
              />
              <label htmlFor="showAdminAll" className="selector mt-1" />
            </div>
          )}
        </div>
        <h2 className="text-lg font-semibold text-gray-700 mb-4">Overview</h2>
        <MetricOverviewGroup
          promptId={selectedPV?.prompt?.id}
          version={selectedPV?.version?.version}
          startDate={dateRange?.startDate as Date}
          endDate={dateRange?.endDate as Date}
          includeArchived={includeArchived}
          adminShowAll={adminShowAll}
          onData={handleOverviewDataChange}
        />

        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 justify-start gap-x-16 gap-y-8 mt-8">
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Token Usage</h2>
            {!bucketedMetrics || isLoading ? (
              <Skeleton count={chartSkeletons} />
            ) : (
              <ResponsiveContainer height={chartContainerHeight}>
                <LineChart data={bucketedMetrics} margin={{ left: 10 }} syncId="p">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={labelSizeSmall} dataKey="date" tickFormatter={(tick) => formatDateLabel(tick)} dy={5} />
                  <YAxis tick={labelSizeSmall} tickFormatter={(tick) => tick.toLocaleString()} dy={-5} />
                  <Tooltip formatter={(value) => value.toLocaleString()} />
                  <Legend wrapperStyle={labelSizeSmall} />
                  <Line type="monotone" dataKey="totalRequestTokens" stroke={CHART_COLORS[1]} name="Prompt Tokens" />
                  <Line
                    type="monotone"
                    dataKey="totalResponseTokens"
                    stroke={CHART_COLORS[2]}
                    name="Completion Tokens"
                  />
                  <Line type="monotone" dataKey="totalTokens" stroke={CHART_COLORS[3]} name="Total Tokens" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Model Usage</h2>
            {!modelsUsed || isLoading ? (
              <Skeleton count={chartSkeletons} />
            ) : (
              <ResponsiveContainer height={chartContainerHeight}>
                <AreaChart data={modelMetrics} margin={{ left: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={labelSizeSmall} dataKey="date" dy={5} />
                  <YAxis tick={labelSizeSmall} tickFormatter={(tick) => tick.toLocaleString()} dy={-5} />
                  <Tooltip formatter={(value) => value.toLocaleString()} />
                  <Legend wrapperStyle={labelSizeSmall} />
                  {modelsUsed.map((model: any, index: number) => (
                    <Area
                      key={model}
                      type="monotone"
                      dataKey={model}
                      stroke={CHART_COLORS[index]}
                      fill={CHART_COLORS[index]}
                      name={model}
                    />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Timings</h2>
            {!bucketedMetrics || isLoading ? (
              <Skeleton count={chartSkeletons} />
            ) : (
              <ResponsiveContainer height={chartContainerHeight}>
                <LineChart data={bucketedMetrics} margin={{ left: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={labelSizeSmall} dataKey="date" tickFormatter={(tick) => formatDateLabel(tick)} dy={5} />
                  <YAxis tick={labelSizeSmall} tickFormatter={(tick) => tick.toFixed(2) + 's'} dy={-5} />
                  <Tooltip formatter={(value) => (value as number).toFixed(2) + 's'} />
                  <Legend wrapperStyle={labelSizeSmall} />
                  <Line type="monotone" dataKey="avgLatency" stroke={CHART_COLORS[1]} name="Latency" />
                  <Line type="monotone" dataKey="avgTtfb" stroke={CHART_COLORS[2]} name="TTFB" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Cost</h2>
            {!bucketedMetrics || isLoading ? (
              <Skeleton count={chartSkeletons} />
            ) : (
              <ResponsiveContainer height={chartContainerHeight}>
                <LineChart data={bucketedMetrics} margin={{ left: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={labelSizeSmall} dataKey="date" tickFormatter={(tick) => formatDateLabel(tick)} dy={5} />
                  <YAxis tick={labelSizeSmall} tickFormatter={(tick) => formatCurrency(tick)} dy={-5} />
                  <Tooltip formatter={(value) => formatCurrency(value.toString())} />
                  <Legend wrapperStyle={labelSizeSmall} />
                  <Line type="monotone" dataKey="totalCost" stroke={CHART_COLORS[1]} name="Cost" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Scoring</h2>
            {!bucketedMetrics || isLoading ? (
              <Skeleton count={chartSkeletons} />
            ) : (
              <ResponsiveContainer height={chartContainerHeight}>
                <LineChart data={bucketedMetrics} margin={{ left: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={labelSizeSmall} dataKey="date" tickFormatter={(tick) => formatDateLabel(tick)} dy={5} />
                  <YAxis tick={labelSizeSmall} tickFormatter={(tick) => tick.toFixed(2)} dy={-5} />
                  <Tooltip formatter={(value) => (value as number).toFixed(2) + 's'} />
                  <Legend wrapperStyle={labelSizeSmall} />
                  <Line type="monotone" dataKey="avgScore" stroke={CHART_COLORS[1]} name="User Score" />
                  <Line type="monotone" dataKey="avgAnalystScore" stroke={CHART_COLORS[2]} name="Analyst Score" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Usage by Model</h2>
            <div className="w-full">
              {!modelUsage || isLoading ? (
                <div>
                  <Skeleton count={8} />
                </div>
              ) : (
                <ResponsiveContainer height={chartContainerHeight}>
                  <PieChart margin={{ top: 0, left: 30, right: 30, bottom: 0 }}>
                    <Pie
                      data={modelUsage}
                      dataKey="totalInvocations"
                      nameKey="model"
                      cx="50%"
                      cy="50%"
                      label={({ name, value }) => name}
                      fill="#4F46E5"
                      outerRadius={80}>
                      {modelUsage.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => value.toLocaleString()} />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Cost by Model</h2>
            <div className="w-full">
              {!modelUsage || isLoading ? (
                <div>
                  <Skeleton count={8} />
                </div>
              ) : (
                <ResponsiveContainer height={chartContainerHeight}>
                  <PieChart margin={{ top: 0, left: 30, right: 30, bottom: 0 }}>
                    <Pie
                      data={modelUsage}
                      dataKey="totalCost"
                      nameKey="model"
                      cx="50%"
                      cy="50%"
                      label={({ name, value }) => name}
                      fill="#4F46E5"
                      outerRadius={80}>
                      {modelUsage.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => formatCurrency(value.toString())} />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsTab;
