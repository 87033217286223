export enum EvaluationType {
  BIAS = 'bias',
  COHERENCE = 'coherence',
  FLUENCY = 'fluency',
  FACTUAL = 'factual',
  TOXICITY = 'toxicity',
  RELEVANCY = 'relevancy'
}

interface Cost {
  requestCost: number;
  responseCost: number;
  totalCost: number;
}

export interface RatedEvaluation extends Cost {
  model: string;
  type: EvaluationType;
  ratings: number[];
  reasons: string[];
  averageRating: number;
}

export interface Summary extends Cost {
  results: string;
}

export interface Grades extends Cost {
  grades: Record<string, string>;
}
