import { Prompt, Roles, UserBasicInfo } from '../types';
import { FullMessageContent } from '../types/Models';
import {
  PlaygroundMessage,
  PromptMessage,
  PromptTemplate,
  PromptTypes,
  PromptUser,
  PromptVersion,
  PromptVersionTypes,
  ToolActionTypes
} from '../types/Prompt';
import { UserRole } from '../types/User';
import { extractDataStructure } from './handlebars';

/**
 * Formats the given role into a human-readable string.
 * @param role - The role to format.
 * @returns The formatted role as a string.
 */
export const formatRole = (role: Roles) => {
  switch (role) {
    case Roles.OWNER:
      return 'Owner';
    case Roles.READ_WRITE:
      return 'Contributor';
    case Roles.READ_ONLY:
      return 'Read Only';
    case Roles.ADMIN:
      return 'Admin';
  }
};

/**
 * Checks if a prompt is a library item type.
 * @param prompt - The prompt to check.
 * @returns `true` if the prompt is a library item, `false` otherwise.
 */
export const isLibraryItem = (prompt?: Prompt): boolean => isLibraryItemType(prompt?.type);

/**
 * Checks if the given type is a library item type.
 * @param type - The type to check.
 * @returns Returns `true` if the type is a library item type, `false` otherwise.
 */
export const isLibraryItemType = (type?: PromptTypes): boolean =>
  type === PromptTypes.PROMPT_LIBRARY || type === PromptTypes.EVALUATOR_LIBRARY;

/**
 * Checks if the user can manage the library item.
 *
 * @param prompt - The prompt to check.
 * @param user - The user information.
 * @returns A boolean indicating whether the user can manage the library item.
 */
export const userCanManageLibrary = (prompt: Prompt, user: UserBasicInfo): boolean =>
  userCanManageLibraryType(prompt.type, user);

/**
 * Checks if the user can manage a specific library item type.
 *
 * @param type - The type of the library item.
 * @param user - The basic information of the user.
 * @returns A boolean indicating whether the user can manage the library item type.
 */
export const userCanManageLibraryType = (type: PromptTypes, user: UserBasicInfo): boolean =>
  isLibraryItemType(type) && (user.role === UserRole.ADMIN || user.role === UserRole.MAINTAINER);

/**
 * Checks if the user can manage the prompt.
 * @param prompt - The prompt to check.
 * @param user - The user to check.
 * @returns True if the user can manage the prompt, false otherwise.
 */
export const userIsOwner = (prompt: Prompt, user: UserBasicInfo): boolean => {
  if (isLibraryItem(prompt)) {
    return userCanManageLibrary(prompt, user);
  }

  return prompt.userRole === Roles.OWNER;
};

/**
 * Checks if a user can edit a prompt.
 * @param prompt - The prompt to check.
 * @param user - The user to check against.
 * @returns A boolean indicating whether the user can edit the prompt.
 */
export const userCanEditPrompt = (prompt: Prompt, user: UserBasicInfo): boolean => {
  if (isLibraryItem(prompt)) {
    return userCanManageLibrary(prompt, user);
  }

  return prompt.userRole === Roles.READ_WRITE || prompt.userRole === Roles.OWNER;
};

/**
 * Checks if a user can edit an prompt resource.
 * @param promptUsers - An array of PromptUser objects representing the users associated with the application.
 * @param user - The UserBasicInfo object representing the user.
 * @returns A boolean indicating whether the user can edit the prompt.
 */
export const userCanEdit = (appUsers: PromptUser[], user: UserBasicInfo): boolean => {
  const role = appUsers.find((u) => u.id === user.id)?.promptRole;

  return role === Roles.READ_WRITE || role === Roles.OWNER;
};

/**
 * The required template parameters for the evaluator.
 * @type {string[]}
 */
export const evaluatorTemplateParameters = ['response'];

/**
 * Generates a prompt payload based on the given prompt version.
 * @param version - The prompt version.
 * @returns The generated prompt payload.
 */
export const generatePromptPayloadFromVersion = (version: PromptVersion): Record<string, any> => {
  if (version.type === PromptVersionTypes.MESSAGING) {
    return generatePromptPayload(version.messageTemplate);
  } else {
    return generatePromptPayload(version.template);
  }
};

/**
 * Generates a prompt payload based on the provided template.
 * @param template - The template for the prompt payload. It can be either a string or a PromptTemplate object.
 * @returns The generated prompt payload as a Record<string, any>.
 */
export const generatePromptPayload = (template: string | PromptTemplate | undefined): Record<string, any> => {
  if (!template) return {};

  if (typeof template === 'string') return extractDataStructure(template);

  return template?.messages.reduce(
    (acc, message) => {
      return { ...acc, ...extractDataStructure(message.content) };
    },
    extractDataStructure(template.systemPrompt || '')
  );
};

/**
 * Creates a new empty prompt object.
 * @param type - The type of the prompt. Defaults to PromptTypes.PROMPT.
 * @returns A new empty prompt object.
 */
export const newEmptyPrompt = (type: PromptTypes = PromptTypes.PROMPT): Prompt => ({
  id: '',
  name: '',
  description: '',
  tags: [],
  versions: [],
  versionsCount: 0,
  users: [],
  archived: false,
  type,
  created: new Date(),
  updated: new Date()
});

/**
 * Creates a new empty prompt version.
 * @param promptId - The ID of the prompt.
 * @param type - The type of the prompt version.
 * @returns A new empty prompt version.
 */
export const newEmptyVersion = (
  promptId?: string,
  type: PromptVersionTypes = PromptVersionTypes.MESSAGING
): PromptVersion => ({
  id: '',
  promptId: '',
  version: 1,
  environments: [],
  type,
  template: '',
  messageTemplate: undefined,
  model: '',
  parameters: [],
  customProps: {},
  changeLog: 'Initial version',
  tags: [],
  created: new Date(),
  updated: new Date()
});

/**
 * Checks if a given message is a PlaygroundMessage.
 * @param message - The message to check.
 * @returns True if the message is a PlaygroundMessage, false otherwise.
 */
const messageIsPlaygroundMessage = (message: PlaygroundMessage | PromptMessage): message is PlaygroundMessage => {
  const m = message as PlaygroundMessage;
  return m.type !== undefined || m.text !== undefined;
};

/**
 * Returns the content string of a PlaygroundMessage.
 * If the message is undefined or null, an empty string is returned.
 * If the message has a `text` property, its value is returned after trimming.
 * Otherwise, if the message has a `content` property, its value is returned after trimming.
 * @param message - The PlaygroundMessage object.
 * @returns The content string of the PlaygroundMessage.
 */
export const getMessageContentString = (message?: PlaygroundMessage | PromptMessage): string => {
  if (!message) return '';

  if (messageIsPlaygroundMessage(message)) {
    if (message.type === ToolActionTypes.TOOL_USE || message.type === ToolActionTypes.TOOL_RESULT) {
      return JSON.stringify(message, null, 2);
    }

    return message.text?.trim() || '';
  } else {
    return message.content.trim();
  }
};

/**
 * Determines if a given message is related to tool usage.
 *
 * @param message - The message to check.
 * @returns `true` if the message type is either `TOOL_USE` or `TOOL_RESULT`, otherwise `false`.
 */
export const isToolMessage = (message: PlaygroundMessage) =>
  message.type === ToolActionTypes.TOOL_USE || message.type === ToolActionTypes.TOOL_RESULT;

/**
 * Generates a combined prompt string from the provided full messages content.
 *
 * @param {FullMessageContent} fullMessages - The full messages content containing system prompt and messages.
 * @returns {string} The combined prompt string with system prompt and user/assistant messages.
 */
export const generateCombinedPrompt = (fullMessages: FullMessageContent) => {
  let response = [];
  if (fullMessages.systemPrompt) {
    response.push(`System: ${fullMessages.systemPrompt}`);
  }

  response = response.concat(
    fullMessages.messages.map((m) => {
      return m.role === 'user' ? `User: ${m.text || m.content}` : `Assistant: ${m.text || m.content}`;
    })
  );

  return response.join('\n');
};

export const exportedForTesting = {
  messageIsPlaygroundMessage
};
