import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * Props for the HomepageTile component.
 */
interface Props {
  title: string;
  icon: IconProp;
  text: string | number | boolean | undefined;
  onClick?: () => void;
}

/**
 * Represents a tile component for the homepage.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the tile.
 * @param {React.ReactNode} props.icon - The icon to be displayed in the tile.
 * @param {string | number | boolean | undefined} props.text - The text to be displayed in the tile.
 * @param {Function} props.onClick - The function to be called when the tile is clicked.
 * @returns {JSX.Element} The rendered HomepageTile component.
 */
const HomepageTile: React.FC<Props> = ({ title, icon, text, onClick }: Props) => {
  const [_text, setText] = useState<string | number | boolean | undefined>(text);

  useEffect(() => {
    setText(text);
  }, [text]);

  // sdffds

  return (
    <li
      className="group rounded-lg border-gray-300 border p-3 shadow-lg hover:bg-indigo-600 hover:cursor-pointer w-72 h-32"
      onClick={() => onClick && onClick()}>
      <div className="flex">
        <h3 className="text-gray-800 font-semibold group-hover:text-white overflow-hidden flex-none mr-4">
          <FontAwesomeIcon icon={icon} className="h-14 w-14 text-indigo-500 group-hover:text-white" />
        </h3>
        <div className="flex-1">
          <h4 className="text-2xl text-gray-600 group-hover:text-white w-full">{title}</h4>
          <div className="mt-3">
            <div className="text-5xl text-gray-600 group-hover:text-white">
              {_text === undefined ? <Skeleton count={1} /> : _text}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default HomepageTile;
