import { faMoneyBill1, faClock } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistance } from 'date-fns';
import { formatCurrency } from '../../common/utils';
import { LoadingIcon } from '../common/Icons';

export interface Progress {
  startTime: number;
  endTime: number;
  completedCount: number;
  totalMetrics: number;
  totalCount: number;
  percentageComplete: number;
  status: string;
  failedCount: number;
  isDone: boolean;
}

interface Props {
  progress: Progress;
  cost: number;
}

const ProgressTracker: React.FC<Props> = ({ progress, cost }: Props) => {
  return (
    <div className="text-sm text-gray-700 w-1/4">
      <div className="mb-2 text-lg text-gray-700">
        {progress.isDone ? (
          <div>
            Analysis Complete <FontAwesomeIcon icon={faCircleCheck} className="w-4 h-4 text-green-600" />{' '}
          </div>
        ) : (
          <div>
            Performing Analysis <LoadingIcon className="!inline ml-1 !w-4 !h-4" color="#8B5CF6" />
          </div>
        )}
      </div>
      {!progress.isDone && (
        <div className="flex w-full items-center">
          <div className="w-full overflow-hidden bg-gray-200 rounded-full mb-2 flex-1 mt-1.5" title={progress.status}>
            <div
              className="bg-indigo-500 h-5 space-x-2 text-white rounded-full text-center text-xs pt-0.5"
              style={{ width: `${progress.percentageComplete}%` }}>
              <span>
                {progress.completedCount} of {progress.totalCount}
              </span>
            </div>
          </div>
        </div>
      )}
      <div>
        <span className="text-gray-500 text-base mr-1.5">
          <FontAwesomeIcon icon={faMoneyBill1} />
        </span>
        {formatCurrency(cost, 4)}
      </div>
      <div>
        <span className="text-gray-500 text-base mr-1.5">
          <FontAwesomeIcon icon={faClock} />
        </span>
        {progress?.isDone
          ? formatDistance(progress.startTime, progress.endTime)
          : formatDistance(progress.startTime, Date.now(), { includeSeconds: true })}
      </div>
      {progress?.failedCount > 0 && (
        <div className="text-red-500">
          <FontAwesomeIcon icon={faCircleExclamation} className="w-4 h-4" /> {progress?.failedCount || 0} failed
        </div>
      )}
    </div>
  );
};

export default ProgressTracker;
