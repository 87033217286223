const listenersMap = new Map<(event: CustomEvent<any>) => void, (event: Event) => void>();

/**
 * Subscribes a listener to a custom event.
 *
 * @template T - The type of the event detail.
 * @param {string} eventName - The name of the event to listen for.
 * @param {(event: CustomEvent<T>) => void} listener - The callback function to be invoked when the event is dispatched.
 */
const subscribe = <T>(eventName: string, listener: (event: CustomEvent<T>) => void) => {
  const wrappedListener = (event: Event) => listener(event as CustomEvent<T>);
  listenersMap.set(listener, wrappedListener);
  document.addEventListener(eventName, wrappedListener);
};

/**
 * Unsubscribes a listener from a custom event.
 *
 * @template T - The type of the event detail.
 * @param {string} eventName - The name of the event to unsubscribe from.
 * @param {(event: CustomEvent<T>) => void} listener - The event listener to remove.
 */
const unsubscribe = <T>(eventName: string, listener: (event: CustomEvent<T>) => void) => {
  const wrappedListener = listenersMap.get(listener);
  if (wrappedListener) {
    document.removeEventListener(eventName, wrappedListener);
    listenersMap.delete(listener);
  }
};

/**
 * Publishes a custom event with the specified name and data.
 *
 * @template T - The type of the event data.
 * @param {string} eventName - The name of the event to publish.
 * @param {T} data - The data to include in the event's detail property.
 */
const publish = <T>(eventName: string, data: T) =>
  document.dispatchEvent(new CustomEvent<T>(eventName, { detail: data }));

export { publish, subscribe, unsubscribe };
