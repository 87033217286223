import { formatCurrency } from '../../common/utils';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CompletionMetric } from '../../types/Performance';
import { useEffect, useState } from 'react';
import { RatedEvaluation } from '../../types/Evaluations';
import Skeleton from 'react-loading-skeleton';
import { CHART_COLORS } from '../../constants';

/**
 * Props for the PerformanceGraph component.
 */
interface Props {
  metrics: CompletionMetric[];
  coherence: RatedEvaluation[];
  fluency: RatedEvaluation[];
  bias: RatedEvaluation[];
  toxicity: RatedEvaluation[];
  relevancy: RatedEvaluation[];
  factual: RatedEvaluation[];
}

interface ScoredEvaluationGraphProps {
  data: Record<string, number | string>[];
  name: string;
  desc: string;
  color?: string;
}

const labelSizeSmall = { fontSize: '0.85em' };
const barRadius: [number, number, number, number] = [5, 5, 0, 0];
const chartContainerHeight = 300;
const chartSkeletons = 11;

const xTickFormatter = (data: any[], value: string): string => {
  if (data.length > 4) {
    const parts = value.split(' ');
    if (parts.length > 1) {
      return `${parts[0].substring(0, 1)}. ${parts.pop()}`;
    }
  }
  return value;
};

const ScoredEvaluationGraph: React.FC<ScoredEvaluationGraphProps> = (props: ScoredEvaluationGraphProps) => {
  const [name, desc, data, color] = [props.name, props.desc, props.data, props.color];
  return (
    <div>
      <h2 className="text-lg font-semibold text-gray-700 mb-0">{name}</h2>
      <div className="text-xs text-gray-500 mb-4">{desc}</div>
      {data.length === 0 ? (
        <Skeleton count={chartSkeletons} />
      ) : (
        <ResponsiveContainer height={chartContainerHeight}>
          <BarChart data={data} margin={{ left: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(data, v)} />
            <YAxis tick={labelSizeSmall} ticks={[1, 2, 3, 4, 5]} tickMargin={0} />
            <Tooltip />
            <Legend wrapperStyle={labelSizeSmall} />
            <Bar dataKey="avgRating" fill={color ?? CHART_COLORS[0]} name="Rating" radius={barRadius} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

/**
 * Renders a performance graph component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Array<Metric>} props.metrics - The metrics data for the graph.
 * @param {Array<RatedEvaluation>} props.coherence - The coherence data for the graph.
 * @param {Array<RatedEvaluation>} props.fluency - The fluency data for the graph.
 * @returns {JSX.Element} The rendered component.
 */
const PerformanceGraph: React.FC<Props> = ({
  metrics,
  coherence,
  fluency,
  bias,
  toxicity,
  relevancy,
  factual
}: Props) => {
  const [coherenceData, setCoherenceData] = useState<Record<string, number | string>[]>([]);
  const [fluencyData, setFluencyData] = useState<Record<string, number | string>[]>([]);
  const [biasData, setBiasData] = useState<Record<string, number | string>[]>([]);
  const [toxicityData, setToxicityData] = useState<Record<string, number | string>[]>([]);
  const [relevancyData, setRelevancyData] = useState<Record<string, number | string>[]>([]);
  const [factualData, setFactualData] = useState<Record<string, number | string>[]>([]);

  useEffect(() => {
    setMetricData(coherence, setCoherenceData);
    setMetricData(fluency, setFluencyData);
    setMetricData(bias, setBiasData);
    setMetricData(toxicity, setToxicityData);
    setMetricData(relevancy, setRelevancyData);
    setMetricData(factual, setFactualData);
  }, [fluency, bias, toxicity, relevancy, factual, coherence]);

  const setMetricData = (
    metricArray: Array<{ model: string; ratings: number[] }>,
    setDataFunction: React.Dispatch<React.SetStateAction<Record<string, number | string>[]>>
  ) => {
    const processedData = metricArray.map((item) => {
      const ratings = item.ratings;
      const avgRating = ratings.reduce((acc, val) => acc + val, 0) / ratings.length;
      return {
        model: item.model,
        avgRating
      };
    });

    setDataFunction(processedData);
  };

  return (
    <>
      <div className="mt-8 grid grid-cols-2 gap-x-16 gap-y-8 ">
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Latency</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the average latency for the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}s`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="minLatency" fill={CHART_COLORS[0]} name="Minimum" radius={barRadius} />
                <Bar dataKey="avgLatency" fill={CHART_COLORS[1]} name="Average" radius={barRadius} />
                <Bar dataKey="maxLatency" fill={CHART_COLORS[2]} name="Maximum" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Time to First Byte</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the average time to first byte for the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}s`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="minTtfb" fill={CHART_COLORS[0]} name="Minimum" radius={barRadius} />
                <Bar dataKey="avgTtfb" fill={CHART_COLORS[1]} name="Average" radius={barRadius} />
                <Bar dataKey="maxTtfb" fill={CHART_COLORS[2]} name="Maximum" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Cost</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the cost of model&lsquo;s predicted answer. Includes request and response costs.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(tick) => formatCurrency(tick, 4)} />
                <Tooltip formatter={(value) => formatCurrency(value.toString(), 5)} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRequestCost" stackId="a" fill={CHART_COLORS[0]} name="Request" />
                <Bar dataKey="avgResponseCost" stackId="a" fill={CHART_COLORS[2]} name="Response" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Request Tokens</h2>
          <div className="text-xs text-gray-500 mb-4">Measures the total token count in the model&lsquo;s prompt.</div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRequestTokens" fill={CHART_COLORS[0]} name="Average" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Response Tokens</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the total token count in the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgResponseTokens" fill={CHART_COLORS[0]} name="Average" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <ScoredEvaluationGraph
          data={coherenceData}
          name="Coherence"
          desc="Measures that the model&lsquo;s output that flows smoothly, reads naturally, and resembles human-like language."
        />
        <ScoredEvaluationGraph
          data={factualData}
          name="Factual"
          desc="Measures the factual accuracy of the model&lsquo;s predicted answer."
        />
        <ScoredEvaluationGraph
          data={fluencyData}
          name="Fluency"
          desc="Measures the grammatical proficiency of a model&lsquo;s predicted answer."
        />
        <ScoredEvaluationGraph
          data={relevancyData}
          name="Relevancy"
          desc="Measures the relevance of the model&lsquo;s predicted answer to the prompt."
        />
        <ScoredEvaluationGraph
          data={toxicityData}
          name="Toxicity"
          desc="Measures the presence of toxic language in the model&lsquo;s predicted answer."
        />
        <ScoredEvaluationGraph
          data={biasData}
          name="Bias"
          desc="Measures the presence of bias in the model&lsquo;s predicted answer."
        />
      </div>
    </>
  );
};

export default PerformanceGraph;
