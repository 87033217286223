import { differenceInMilliseconds, parseISO } from 'date-fns';
import {
  AdminStats,
  Experiment,
  Model,
  Pipeline,
  PipelineEvaluator,
  PipelineRun,
  Prompt,
  PromptBucketedMetric,
  PromptMetric,
  PromptMetricOverview,
  PromptMetricPage,
  PromptMetricTokenDetails,
  PromptUser,
  PromptVersion,
  UserBasicInfo,
  Variant
} from '../types';
import { Health } from '../types/Admin';
import { RatedEvaluation, Summary } from '../types/Evaluations';
import { MetricsCompare, ModelBucketedMetric } from '../types/Metrics';
import { ModelRunResult, ModelRunResultMessages } from '../types/Models';
import {
  PipelineCompletion,
  PipelineEvaluation,
  PipelineMetadata,
  PipelineRunDataPage,
  PipelineRunPage,
  PipelineScores,
  PipelineVersionScore
} from '../types/PipelineEvaluations';
import { PromptAndVersion, PromptTemplate, PromptVersionTypes } from '../types/Prompt';
import { UserAuth, UserStats } from '../types/User';
import { Webhook } from '../types/Webhooks';
import { camelToSnake, snakeToCamel } from './utils';

/**
 * Parses a date string in ISO format, ensuring it is in UTC.
 *
 * This function attempts to parse the given date string as an ISO date,
 * appending a 'Z' to ensure it is treated as UTC. If parsing fails,
 * it falls back to creating a new Date object from the string.
 *
 * @param date - The date string in ISO format to be parsed.
 * @returns A Date object representing the parsed date.
 */
const parseServerISO = (date: string) => {
  try {
    return parseISO(date.slice(0, 23) + 'Z');
  } catch (e) {
    return new Date(date);
  }
};

/**
 * Maps the base API object by parsing the `created` and `updated` ISO date strings.
 *
 * @param api - The API object containing `created` and `updated` ISO date strings.
 * @returns A new object with the parsed `created` and `updated` dates.
 */
export const baseApiMapping = (api: any) => {
  let created, updated;

  created = parseServerISO(api.created);
  updated = parseServerISO(api.updated);

  return { ...api, created, updated };
};

/**
 * Transforms keys in an object that contain snake case to camelcase.
 * @param obj - The object to remove keys from.
 * @returns The object with keys mapped to camelcase.
 */
export const transformKeys = (o: any) => {
  if (!o) {
    console.warn('transformKeys: object is null');
    return o;
  }
  return Object.keys(o).reduce((obj: any, key) => {
    obj[snakeToCamel(key)] = o[key];
    return obj;
  }, {});
};

/**
 * Transforms the keys of an object to snake_case format, suitable for API requests.
 *
 * @param o - The object to transform.
 * @returns The transformed object with keys in snake_case format.
 */
export const transformKeysToApi = (o: any) =>
  Object.keys(o).reduce((obj: any, key) => {
    obj[camelToSnake(key)] = o[key];
    return obj;
  }, {});

/**
 * Maps API authentication data to UserAuth type.
 * @param auth - The API authentication data.
 * @returns The mapped UserAuth object.
 */
export const apiToAuthType = (auth: any): UserAuth => {
  return transformKeys(auth);
};

/**
 * Maps API user data to UserBasicInfo type.
 * @param apiUser - The API user data.
 * @param includePrompts - Whether to include prompts in the user data.
 * @returns The mapped UserBasicInfo object.
 */
export const apiToUserType = (apiUser: any, includePrompts: boolean = false): UserBasicInfo => {
  const resp = transformKeys({
    ...baseApiMapping(apiUser),
    id: apiUser.id,
    expires: new Date(apiUser.expires)
  });

  if (!includePrompts) delete resp['prompts'];

  return resp;
};

/**
 * Maps API prompt users to prompt users.
 *
 * @param apiPromptUsers - The API prompt users to transform.
 * @returns The transformed prompt users.
 */
export const apiToPromptUsers = (apiPromptUsers: any): PromptUser[] =>
  apiPromptUsers.map((apiPromptUser: any) => transformKeys(apiPromptUser));

/**
 * Transforms the keys of the given API user statistics object to match the UserStats format.
 *
 * @param apiUserStats - The user statistics object received from the API.
 * @returns The transformed user statistics object in the UserStats format.
 */
export const apiToUserStats = (apiUserStats: any): UserStats => transformKeys(apiUserStats);

/**
 * Maps API prompt data to Prompt type.
 * @param apiPrompt - The API prompt data.
 * @returns The mapped Prompt object.
 */
export const apiToPromptType = (apiPrompt: any): Prompt =>
  transformKeys({
    ...baseApiMapping(apiPrompt),
    users: apiToPromptUsers(apiPrompt.users),
    defaultPipeline: apiPrompt.default_pipeline ? transformKeys(apiPrompt.default_pipeline) : undefined
  });

/**
 * Maps API prompt version data to PromptVersion type.
 * @param apiPromptVersion - The API prompt version data.
 * @returns The mapped PromptVersion object.
 */
export const apiToPromptVersionType = (apiPromptVersion: any): PromptVersion =>
  transformKeys({
    ...baseApiMapping(apiPromptVersion),
    parameters: Object.entries(apiPromptVersion.parameters).map(([name, value]) => ({
      name,
      value
    })),
    author: apiPromptVersion.author ? transformKeys(apiPromptVersion.author) : null,
    messageTemplate: apiPromptVersion.message_template
      ? apiToPromptTemplateType(apiPromptVersion.message_template)
      : undefined,
    type: apiPromptVersion.type || PromptVersionTypes.LEGACY,
    tools: apiPromptVersion.tools && apiPromptVersion.tools.map(transformKeys)
  });

/**
 * Converts an API prompt template to a PromptTemplate object.
 * @param apiPromptTemplate - The API prompt template object.
 * @returns The converted PromptTemplate object.
 */
export const apiToPromptTemplateType = (apiPromptTemplate: any): PromptTemplate =>
  transformKeys({
    ...apiPromptTemplate,
    messages: apiPromptTemplate.messages.map(transformKeys)
  });

/**
 * Maps API prompt version object to a ForkedPromptVersion object.
 * @param apiPromptVersion - The API prompt version object.
 * @returns The mapped ForkedPromptVersion object.
 */
export const apiToForkedPromptVersionType = (apiPromptVersion: any): PromptAndVersion => ({
  prompt: apiToPromptType(apiPromptVersion.prompt),
  version: apiToPromptVersionType(apiPromptVersion.version)
});

/**
 * Maps API prompt metric data to PromptMetric type.
 * @param metric - The API prompt metric data.
 * @returns The mapped PromptMetric object.
 */
export const apiToPromptMetric = (metric: any): PromptMetric => {
  const requestAt = parseISO(metric.request_at.slice(0, 23) + 'Z');
  const responseAt = parseISO(metric.response_at.slice(0, 23) + 'Z');
  const fetchTemplateTime = metric.fetch_template_time * 1000;
  const compileTemplateTime = metric.compile_template_time * 1000;
  const inferenceTime = differenceInMilliseconds(responseAt, requestAt);
  const calculatedTotalTime = fetchTemplateTime + compileTemplateTime + inferenceTime + metric.analytics_time;

  return transformKeys({
    ...baseApiMapping(metric),
    ...metric,
    tokensByModel: apiToPromptMetricTokenDetails(metric.tokens),
    totalCost: metric.tokens.request_cost + metric.tokens.response_cost,
    requestAt,
    responseAt,
    fetchTemplateTime,
    compileTemplateTime,
    inferenceTime,
    calculatedTotalTime
  });
};

/**
 * Maps API prompt metric page data to PromptMetricPage type.
 * @param apiPage - The API prompt metric page data.
 * @returns The mapped PromptMetricPage object.
 */
export const apiToPromptMetricPage = (apiPage: any): PromptMetricPage =>
  transformKeys({
    ...apiPage,
    items: apiPage.items.map((metric: any) => apiToPromptMetric(metric))
  });

/**
 * Maps API bucketed metric object to a prompt bucketed metric object.
 * @param apiBucketedMetric - The API bucketed metric object to transform.
 * @returns The transformed prompt bucketed metric object.
 */
export const apiToPromptBucketedMetric = (apiBucketedMetric: any): PromptBucketedMetric =>
  transformKeys({
    ...apiBucketedMetric
  });

/**
 * Maps an API bucketed model metric to a record of prompt bucketed metrics.
 * @param apiBucketedModelMetric - The API bucketed model metric to convert.
 * @returns A record of prompt bucketed metrics, where the keys are dates and the values are arrays of prompt bucketed metrics.
 */
export const apiToPromptBucketedModelMetric = (apiBucketedModelMetric: any): Record<string, ModelBucketedMetric[]> => {
  return Object.entries(apiBucketedModelMetric.dates).reduce(
    (acc: Record<string, ModelBucketedMetric[]>, [date, metrics]) => {
      acc[date] = (metrics as any[]).map((metric: any) => transformKeys({ ...metric }));
      return acc;
    },
    {}
  );
};

/**
 * Maps API metric object to PromptMetricTokenDetails.
 * @param metric - The API metric object.
 * @returns The mapped PromptMetricTokenDetails object.
 */
export const apiToPromptMetricTokenDetails = (metric: any): PromptMetricTokenDetails =>
  transformKeys({
    ...metric,
    totalCost: metric.request_cost + metric.response_cost,
    totalTokens: metric.request_tokens + metric.response_tokens
  });

/**
 * Maps API prompt metric overview data to PromptMetricOverview type.
 * @param metric - The API prompt metric overview data.
 * @returns The mapped PromptMetricOverview object.
 */
export const apiToPromptMetricOverviewType = (metric: any): PromptMetricOverview => {
  const data = {
    ...baseApiMapping(metric),
    tokensByModel: metric.tokens_by_model.map((model: any) => apiToPromptMetricTokenDetails(model))
  };

  data.totalCost = data.tokensByModel.reduce(
    (acc: number, model: any) => acc + model.requestCost + model.responseCost,
    0
  );

  return transformKeys(data);
};

/**
 * Maps an API object to a MetricsCompare object.
 * @param compare - The API object to convert.
 * @returns The mapped MetricsCompare object.
 */
export const apiToMetricsCompare = (compare: any): MetricsCompare =>
  transformKeys({
    version1: { ...transformKeys(compare.version1), version: apiToPromptVersionType(compare.version1.version) },
    version2: { ...transformKeys(compare.version2), version: apiToPromptVersionType(compare.version2.version) }
  });

/**
 * Maps API model data to Model type.
 * @param apiModel - The API model data.
 * @returns The mapped Model object.
 */
export const apiToModelType = (apiModel: any): Model =>
  transformKeys({
    ...apiModel,
    inputTokensCost: apiModel.tokens_in_cost,
    completionTokensCost: apiModel.tokens_out_cost,
    parametersMessages: apiModel.parameters_messages.map((parameter: any) => {
      return transformKeys(parameter);
    }),
    parametersCompletion: apiModel.parameters_completion.map((parameter: any) => {
      return transformKeys(parameter);
    })
  });

/**
 * Maps API result to a performance run result type.
 * @param apiResult The API result to convert.
 * @returns The mapped performance run result type.
 */
export const apiToModelRunResultType = (apiResult: any): ModelRunResult =>
  transformKeys({
    ...apiResult,
    modelId: apiResult.mid,
    tokens: apiToPromptMetricTokenDetails(apiResult.tokens)
  });

export const apiToModelRunMessagesResultType = (apiResult: any): ModelRunResultMessages =>
  transformKeys({
    ...apiResult,
    modelId: apiResult.mid,
    tokens: apiToPromptMetricTokenDetails(apiResult.tokens),
    response: transformKeys({
      ...apiResult.response,
      content: apiResult.response.content.map((v: any) => {
        return { ...transformKeys(v), role: apiResult.response.role };
      })
    })
  } as ModelRunResultMessages);

/**
 * Maps API rated evaluation object to a RatedEvaluation object.
 * @param apiRatedEvaluation - The API rated evaluation object to convert.
 * @returns The mapped RatedEvaluation object.
 */
export const apiToRatedEvaluationType = (apiRatedEvaluation: any): RatedEvaluation => {
  return transformKeys({
    ...apiRatedEvaluation
  });
};

/**
 * Maps API summary data to any type.
 * @param apiSummary - The API summary data.
 * @returns The mapped summary object.
 */
export const apiToSummaryType = (apiSummary: any): Summary => {
  return transformKeys({
    ...apiSummary
  });
};

/**
 * Maps API experiment object to an Experiment object.
 * @param apiExperiment - The API experiment object to convert.
 * @returns The mapped Experiment object.
 */
export const apiToExperimentType = (apiExperiment: any): Experiment => {
  return transformKeys({
    ...baseApiMapping(apiExperiment)
  });
};

/**
 * Maps API variant to a Variant object.
 * @param apiVariant - The API variant to convert.
 * @returns The mapped Variant object.
 */
export const apiToVariantType = (apiVariant: any): Variant => {
  return transformKeys({
    ...baseApiMapping(apiVariant)
  });
};

/**
 * Maps an API object to a Pipeline object.
 * @param pipeline - The API object representing a pipeline.
 * @returns The mapped Pipeline object.
 */
export const apiToPipelineType = (pipeline: any): Pipeline =>
  transformKeys({
    ...baseApiMapping(pipeline),
    defaultDataMetadata: transformKeys(pipeline.default_data_metadatas)
  });

/**
 * Transforms an API object to a PipelineEvaluator bject.
 * @param evaluator - The API object representing the evaluation.
 * @returns The transformed PipelineEvaluator object.
 */
export const apiToPipelineEvaluatorType = (evaluator: any): PipelineEvaluator =>
  transformKeys({
    ...baseApiMapping(evaluator)
  });

/**
 * Maps an API object to a PipelineRun object.
 * @param run - The API object representing a run.
 * @returns The transformed PipelineRun object.
 */
export const apiToPipelineRunType = (run: any): PipelineRun =>
  transformKeys({
    ...baseApiMapping(run),
    prompt: run.prompt ? apiToPromptType(run.prompt) : undefined,
    promptVersion: run.prompt_version ? apiToPromptVersionType(run.prompt_version) : undefined,
    metadata: transformKeys(run.metadatas),
    startedAt: parseServerISO(run.started_at),
    completedAt: parseServerISO(run.completed_at)
  });

/**
 * Transforms API default data to pipeline metadata by converting the keys.
 *
 * @param apiDefaultData - The default data received from the API.
 * @returns The transformed pipeline run metadata.
 */
export const apiToPipelineDefaultData = (apiDefaultData: any): PipelineMetadata => transformKeys(apiDefaultData);

/**
 * Transforms an API page object to a PipelineRunPage object.
 * @param apiPage - The API page object to transform.
 * @returns The transformed PipelineRunPage object.
 */
export const apiToPipelineRunPageType = (apiPage: any): PipelineRunPage =>
  transformKeys({
    ...baseApiMapping(apiPage),
    items: apiPage.items.map((run: any) => apiToPipelineRunType(run))
  });

/**
 * Transforms API data into a `PipelineRunDataPage` format by mapping the base API data
 * and transforming the keys of each item in the `items` array.
 *
 * @param apiData - The raw data received from the API.
 * @returns A `PipelineRunDataPage` object with transformed keys.
 */
export const apiToPipelinesData = (apiData: any): PipelineRunDataPage =>
  transformKeys({
    ...baseApiMapping(apiData),
    items: apiData.items.map((run: any) => transformKeys(run))
  });

/**
 * Transforms an API result into a PipelineEvaluation object.
 * @param apiResult The API result to transform.
 * @returns The transformed PipelineEvaluation object.
 */
export const apiToPipelineEvaluationResultType = (apiResult: any): PipelineEvaluation =>
  transformKeys({
    ...baseApiMapping(apiResult)
  });

/**
 * Maps an API completion object to a PipelineCompletion object.
 * @param completion The API completion object to be transformed.
 * @returns The transformed PipelineCompletion object.
 */
export const apiToPipelineCompletionType = (completion: any): PipelineCompletion =>
  transformKeys({
    ...baseApiMapping(completion),
    evaluations: completion.evaluation_results.map((evaluation: any) => apiToPipelineEvaluationResultType(evaluation))
  });

/**
 * Maps API score data to PipelineScores object.
 * @param scoreData - The score data received from the API.
 * @returns The converted PipelineScores object.
 */
export const apiToPipelineScores = (scoreData: any): PipelineScores => {
  const pipelineScores: PipelineScores = {
    pipelineId: scoreData.pipeline_id,
    pipelineScores: {}
  };

  for (const [pipelineRunId, scores] of Object.entries(scoreData.scores)) {
    pipelineScores.pipelineScores[pipelineRunId] = (scores as any[]).map((score: any) => {
      score.created = new Date(score.created);
      return transformKeys(score);
    });
  }

  return pipelineScores;
};

/**
 * Transforms the keys of the given score data to match the `PipelineVersionScore` type.
 *
 * @param scoreData - The score data object with keys to be transformed.
 * @returns The transformed score data as a `PipelineVersionScore`.
 */
export const apiToPipelineVersionScore = (scoreData: any): PipelineVersionScore => transformKeys(scoreData);

/**
 * Maps API stats object to the admin stats object.
 * @param apiStats - The API stats object to be converted.
 * @returns The converted AdminStats object.
 */
export const apiToAdminStats = (apiStats: any): AdminStats => transformKeys(apiStats);

/**
 * Maps API health object to a Health object.
 * @param apiHealth - The API health object.
 * @returns The converted Health object.
 */
export const apiToHealth = (apiHealth: any): Health => ({
  api: transformKeys(apiHealth.api),
  postgres: transformKeys(apiHealth.postgres),
  redis: transformKeys(apiHealth.redis)
});

/**
 * Transforms the keys of the given evaluator object to match the Webhook type.
 *
 * @param evaluator - The input object to be transformed.
 * @returns The transformed object with keys matching the Webhook type.
 */
export const apiToWebhookType = (evaluator: any): Webhook =>
  transformKeys({
    ...baseApiMapping(evaluator)
  });
