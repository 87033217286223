import { axiosInstance } from '../api';
import { modelParameterArrayToObject } from '../common/models';
import {
  apiToModelType,
  apiToModelRunResultType,
  apiToRatedEvaluationType,
  transformKeysToApi,
  apiToModelRunMessagesResultType
} from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { Model } from '../types';
import { EvaluationType, Grades, RatedEvaluation, Summary } from '../types/Evaluations';
import { PromptMetricSource } from '../types/Metrics';
import { ModelParameterValue, ModelRunResult, ModelRunResultMessages } from '../types/Models';
import {
  MessageContent,
  PlaygroundMessage,
  PromptMessageRole,
  PromptVersionTypes,
  Tool,
  ToolActionTypes
} from '../types/Prompt';
/**
 * Retrieves the models from the server.
 * @returns A promise that resolves to an array of Model objects.
 * @throws Throws an error if an error occurs while loading the models.
 */
export const getModels = async (): Promise<Model[]> => {
  try {
    const response = await axiosInstance.get(`models/`);
    return response.data.map((api: any) => apiToModelType(api));
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading the models.');
  }
};

/**
 * Scores an evaluation based on the provided type, question, and answer.
 * @param type - The type of evaluation.
 * @param question - The question being evaluated.
 * @param answers - The answers to the question being evaluated.
 * @returns A Promise that resolves to a RatedEvaluation object.
 * @throws Throws an error if an error occurs while scoring the evaluation.
 */
export const scoreEvaluation = async (
  type: EvaluationType,
  question: string,
  answers: string[]
): Promise<RatedEvaluation> => {
  try {
    const response = await axiosInstance.post(`evaluations/scored/${type}`, { type, question, answers });
    return apiToRatedEvaluationType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while scoring the evaluation.');
  }
};

/**
 * Summarizes the results using the provided data.
 * @param data - The data to be used for summarization.
 * @returns A Promise that resolves to a string representing the summarized results.
 * @throws Throws an error if an error occurs while summarizing the results.
 */
export const summarizeResults = async (data: any): Promise<Summary> => {
  try {
    const response = await axiosInstance.post(`evaluations/summarize`, { data });
    return response.data;
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while summarizing the results.');
  }
};

/**
 * Grades the results of an evaluation.
 * @param data - The data to be graded.
 * @returns A Promise that resolves to the graded results.
 * @throws Throws an error if an error occurs while grading the results.
 */
export const gradeModels = async (data: any): Promise<Grades> => {
  try {
    const response = await axiosInstance.post(`evaluations/grade`, { data });
    return response.data;
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while grading the results.');
  }
};

/**
 * Runs a completion with the specified model ID, prompt, and parameters.
 * @param modelId - The ID of the model to run.
 * @param prompt - The prompt to use for running the model.
 * @param parameters - The parameters to pass to the model.
 * @param type - The type of prompt version to use.
 * @param tools - The tools to use for running the model. Optional.
 * @param promptId - The ID of the prompt to use for running the model. Optional.
 * @param promptVersion - The version of the prompt to use for running the model. Optional.
 *
 * @returns A promise that resolves to the result of running the model.
 *
 * @throws Throws an error if an error occurs while running the model.
 */
export const runModel = async (
  modelId: string,
  prompt: string,
  parameters: ModelParameterValue[],
  type: PromptVersionTypes = PromptVersionTypes.LEGACY,
  tools?: Tool[],
  promptId?: string,
  promptVersion?: number,
  source?: PromptMetricSource
): Promise<ModelRunResult> => {
  const payload: any = {
    parameters: modelParameterArrayToObject(parameters),
    type,
    source
  };
  if (promptId && promptVersion) {
    payload.prompt_id = promptId;
    payload.version = promptVersion;
  }
  if (type === PromptVersionTypes.MESSAGING) {
    payload.message_template = JSON.parse(prompt);
    payload.tools = tools?.map((t) => transformKeysToApi(t));
  } else {
    payload.prompt = prompt;
  }

  try {
    const response = await axiosInstance.post(`models/${modelId}/invoke`, payload);
    return apiToModelRunResultType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while running the model.');
  }
};

/**
 * Runs a model with the provided parameters, system prompt, messages, and tools.
 * @param modelId - The ID of the model to run.
 * @param systemPrompt - The system prompt to use for the model run.
 * @param messages - An array of messages to use for the model run.
 * @param parameters - An array of model parameter values.
 * @param tools - Optional tools to use for the model run.
 * @returns A promise that resolves to the result of the model run.
 */
export const runModelMessages = async (
  modelId: string,
  systemPrompt: string | undefined,
  messages: PlaygroundMessage[],
  parameters: ModelParameterValue[],
  tools?: Tool[],
  promptId?: string,
  promptVersion?: number,
  source?: PromptMetricSource
): Promise<ModelRunResultMessages> => {
  // nested messages in a reply are unpacked for the UI. pack them back up for the API.
  const newMessages: any[] = [];

  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    // for a tool use message you need the previous assistant message
    if (msg.type === ToolActionTypes.TOOL_USE) {
      const messagesContent = {
        role: PromptMessageRole.ASSISTANT,
        content: [newMessages.pop(), transformKeysToApi(msg)]
      } as unknown as MessageContent;
      newMessages.push(messagesContent);
    } else if (msg.type === ToolActionTypes.TOOL_RESULT) {
      newMessages.push({ role: PromptMessageRole.USER, content: [transformKeysToApi(msg)] });
    } else {
      newMessages.push(transformKeysToApi(msg));
    }
  }

  const payload: any = {
    parameters: modelParameterArrayToObject(parameters),
    system_prompt: systemPrompt,
    messages: newMessages.map((m) => {
      if (m.text) {
        m.content = m.text;
        m.text = undefined;
      }
      return transformKeysToApi(m);
    }),
    tools: tools?.map((t) => transformKeysToApi(t)) || [],
    source
  };

  if (promptId && promptVersion) {
    payload.prompt_id = promptId;
    payload.version = promptVersion;
  }

  try {
    const { data } = await axiosInstance.post(`models/${modelId}/invoke-messages`, payload);

    return apiToModelRunMessagesResultType(data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while running the model.');
  }
};
