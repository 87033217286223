import { Pageable, PromptVersion } from '.';

/**
 * Represents a metric source.
 */
export enum PromptMetricSource {
  NONE = '',
  API = 'API',
  PLAYGROUND = 'PLAYGROUND',
  ANALYSIS = 'ANALYSIS',
  REPLAY = 'REPLAY'
}

/**
 * Represents the overview of prompt metrics.
 */
export interface PromptMetricOverview {
  totalInvocations: number;
  avgLatency: number;
  avgTtfb: number;
  avgRequestTokens: number;
  avgResponseTokens: number;
  avgScore: number;
  avgAnalystScore: number;
  totalCost: number;
  tokensByModel: PromptMetricTokenDetails[];
}

/**
 * Represents the details of a prompt metric token.
 */
export interface PromptMetricTokenDetails {
  model: string;
  requestTokens: number;
  responseTokens: number;
  requestCost: number;
  responseCost: number;
  totalInvocations: number;
}

/**
 * Represents a metric for a prompt.
 */
export interface PromptMetric {
  id: number;
  requestAt: Date;
  responseAt: Date;
  calculatedTotalTime: number;
  inferenceTime: number;
  latency: number;
  fetchTemplateTime: number;
  compileTemplateTime: number;
  analyticsTime: number;
  model: string;
  ttfb: number;
  tokensByModel: PromptMetricTokenDetails;
  prompt: string;
  completion: string;
  totalCost: number;
  streaming: boolean;
  promptId: string;
  promptVersion: number;
  score: number;
  feedback?: string;
  analystScore: number;
  analystFeedback?: string;
  payload: any;
  metadatas?: any;
  client?: string;
  promptArchived: boolean;
  source: PromptMetricSource;
}

/**
 * Represents a page of prompt metrics.
 */
export interface PromptMetricPage extends Pageable<PromptMetric> {}

/**
 * Represents a bucketed metric for a prompt.
 */
export interface PromptBucketedMetric {
  promptId: string;
  promptVersion: number;
  date: Date;
  totalRequestsTokens: number;
  totalResponseTokens: number;
  totalRequestsCost: number;
  totalResponseCost: number;
  totalCost: number;
  avgLatency: number;
  avgTtfb: number;
  avgScore: number;
  avgAnalystScore: number;
}

/**
 * Represents a model-specific bucketed metric.
 */
export interface ModelBucketedMetric extends PromptBucketedMetric {
  model: string;
  totalCalls: number;
}

/**
 * Represents a summary of metrics for comparing models.
 */
export interface MetricsCompareSummary {
  promptId: string;
  promptVersion: number;
  version: PromptVersion;
  totalInvocations: number;
  firstInvocation: Date;
  lastInvocation: Date;
  avgRequestTokens: number;
  avgResponseTokens: number;
  avgTokens: number;
  avgRequestCost: number;
  avgResponseCost: number;
  avgCost: number;
  avgLatency: number;
  avgTtfb: number;
  avgScore: number;
  avgAnalystScore: number;
  p95Latency: number;
  p95Ttfb: number;
}

/**
 * Represents a comparison between two versions of a model.
 */
export interface MetricsCompare {
  version1: MetricsCompareSummary;
  version2: MetricsCompareSummary;
}

export interface MetricsCompareOverview {
  summary: string;
  suggestions: string[];
}
