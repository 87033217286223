import { Roles } from '.';
import { BaseApi } from './BaseApi';

/**
 * Represents the role of a user.
 */
export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MAINTAINER = 'MAINTAINER'
}

/**
 * Represents a user object.
 */
export interface User {
  email: string;
  password: string;
}

/**
 * Represents a new user.
 */
export interface NewUser extends User {
  name: string;
}

/**
 * Represents the authentication details of a user.
 */
export interface UserAuth {
  tokenType: string;
  accessToken: string;
  active: boolean;
}

/**
 * Represents the basic information of a user.
 */
export interface UserBasicInfo extends BaseApi, UserAuth {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  apiKey: string;
  prompts: UserPrompts[];
  profileImage: string;
  expires: Date;
}

/**
 * Represents the prompts associated with a user in the application.
 */
export interface UserPrompts {
  appId: string;
  role: Roles;
}

/**
 * Interface representing the statistics of a user.
 */
export interface UserStats {
  totalPrompts: number;
  totalVersions: number;
  totalCalls: number;
  totalPipelines: number;
  totalPipelineRuns: number;
}
