import { faFaceSadCry } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tokens } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { publish } from '../../lib/events';
import { getUser, registerOkta, userExists } from '../../services/User';
import { UserBasicInfo } from '../../types';

export const LoginEvent = 'login';

interface Props {
  errorComponent?: React.ComponentType<{ error: Error }>;
  onAuthResume?: () => void;
  loadingElement?: React.ReactElement;
}

const LoginCallback: React.FC<Props> = ({ errorComponent, onAuthResume, loadingElement }: Props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = useState(null);

  useEffect(() => {
    // @ts-ignore
    const isInteractionRequired = oktaAuth.idx.isInteractionRequired || oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    // if the user is being redirected from Okta..
    if (oktaAuth.isLoginRedirect()) {
      let tokens: Tokens;
      oktaAuth.token
        // parse the JWT from the url
        .parseFromUrl()
        // check if the user exists in the database
        .then((res) => {
          tokens = res?.tokens;
          const email = tokens.idToken?.claims.email;

          if (!email) {
            throw new Error('Invalid Okta token received');
          }
          return userExists(email);
        })
        // if the user does not exist, register them
        .then((active) => {
          if (!active) {
            return registerOkta({
              email: tokens.idToken?.claims.email!,
              name: tokens.idToken?.claims.name!,
              password: ''
            });
          }
        })
        // call okta's redirect handler
        .then(() => oktaAuth.handleLoginRedirect(tokens, '/'))
        // get the user's details
        .then(() => getUser(true))
        // publish a login event
        .then((user: UserBasicInfo) => {
          publish(LoginEvent, user);
        })
        // handle any errors
        .catch((error) => {
          console.error('Error during login callback:', error);
          setCallbackError(error);
        });
    }
  }, [oktaAuth]);

  const authError = authState?.error;
  const displayError = callbackError || authError;

  return (
    loadingElement || (
      <div className="mx-auto max-w-lg my-5 bg-gray-50 border-gray-300 border-2 p-5 rounded-lg">
        <div>
          <h1 className="kanit-bold mx-auto text-center text-4xl text-gray-700">
            <span className="text-indigo-500 group-hover:text-black">&#123;</span> Promptly{' '}
            <span className="text-indigo-500 group-hover:text-black">&#125;</span>
          </h1>
          <h2 className="mt-8 text-center text-2xl font-semibold tracking-tight text-gray-900">
            {!displayError ? 'Signing In' : ''}
          </h2>
        </div>

        <div className="mt-10 text-gray-500 ">
          {displayError?.name && displayError.message && displayError.message.includes('not assigned') && (
            <div>
              <p>Sorry, you need to request access to Promptly.</p>
              <p>
                Click the button below to be taken to ServiceNow to submit an access request. Be sure to select {'"'}
                Promptly{'"'} in the Application field.
              </p>
              <a
                rel="noopener noreferrer"
                href="https://coxauto.service-now.com/ess?id=sc_cat_item&sys_id=b9e1b825db965304143b69c3ca9619dc">
                <button className="standard">Request Access</button>
              </a>
            </div>
          )}
          {displayError && (
            <div>
              <div className="text-indigo-500 mx-auto text-center">
                <FontAwesomeIcon icon={faFaceSadCry} className="w-24 h-24" />
              </div>
              <div className="my-4">We encountered an error while authenticating you with Okta.</div>
              <div className="text-red-500">
                {displayError.name ? `${displayError.name}: ` : ''}
                {displayError?.message ?? displayError?.toString()}
              </div>
              <p className="mt-4">
                Sorry about that! Let us know on{' '}
                <a href="https://coxauto.slack.com/archives/C06V80TMKED" className="font-bold">
                  #promptly
                </a>
                .
              </p>
            </div>
          )}
          <div className="text-center">
            {displayError !== undefined ? (
              <div className="mt-10">
                <a href="/">Go Home</a>
              </div>
            ) : (
              <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-700 w-12 h-12" />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default LoginCallback;
