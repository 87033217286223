import { formatDistanceStrict } from 'date-fns';
import { Prompt, UserBasicInfo } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { formatRole, isLibraryItem, userCanManageLibrary } from '../../common/prompts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPenToSquare, faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faBoxArchive, faCodeFork, faTerminal } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getUserLocal } from '../../services/User';
import { PromptRoles } from '../../types/Prompt';
import { UserRole } from '../../types/User';

/**
 * Props for the PromptTile component.
 */
interface Props {
  prompt?: Prompt;
  onClick?: (prompt: Prompt) => void;
  onManageClick?: (prompt: Prompt) => void;
  onForkClick?: (prompt: Prompt) => void;
  onStarClick?: (prompt: Prompt) => void;
}

/**
 * Renders a prompt tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Prompt} props.prompt - The prompt object to display.
 * @param {Function} props.onClick - The click event handler for the tile.
 * @returns {JSX.Element} The rendered PromptTile component.
 */
const PromptTile: React.FC<Props> = ({ prompt, onManageClick, onForkClick, onClick, onStarClick }: Props) => {
  const [user, setUser] = useState<UserBasicInfo>();

  useEffect(() => {
    const _user = getUserLocal();
    setUser(_user);
  }, [prompt]);

  const handleManageClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onManageClick?.(prompt!);
  };

  const handleForkClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onForkClick?.(prompt!);
  };

  const handleStarClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onStarClick?.(prompt!);
  };

  return (
    <li
      className={`group colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg hover:border-indigo-600 hover:cursor-pointer col-span-1 ${prompt?.userRole === PromptRoles.ADMIN ? 'bg-indigo-50' : ''}`}
      onClick={() => onClick && onClick(prompt!)}>
      <div className="flex items-center justify-between w-full">
        <h3 className="text-gray-800 overflow-hidden">
          <FontAwesomeIcon icon={faTerminal} className="inline w-4 h-4 text-indigo-500 mr-2" />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis inline">
            {prompt ? prompt.name : <Skeleton />}
          </span>
        </h3>
        <div className="ml-2 min-w-8">
          {!prompt ? (
            <Skeleton />
          ) : (
            <div className="flex gap-x-2 text-gray-500 ">
              <FontAwesomeIcon
                icon={prompt.isFavorite ? faStarSolid : faStar}
                className={`${prompt.isFavorite ? 'text-yellow-500 hover:text-gray-500' : 'hover:text-yellow-500'}`}
                onClick={handleStarClick}
              />
              <FontAwesomeIcon
                icon={faCodeFork}
                className="hover:text-indigo-800"
                title="Fork Prompt"
                onClick={handleForkClick}
              />
              {user && (!isLibraryItem(prompt) || userCanManageLibrary(prompt, user)) && (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="hover:text-indigo-800"
                  title="Manage Prompt"
                  onClick={handleManageClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <h4 className="text-sm text-gray-500 mt-0 w-full">
        {user?.role !== UserRole.ADMIN ? formatRole(prompt?.userRole!) : prompt?.userName}
      </h4>
      <h4 className="text-sm text-gray-600 mt-2 h-16 w-full word-break-all line-clamp-3">
        {prompt ? prompt.description : <Skeleton />}
        {/* {prompt?.tags.join(', ')} */}
      </h4>
      <div className="flex justify-between mt-4 items-end">
        <div className="flex text-sm text-gray-600">
          {prompt ? (
            prompt.versionsCount === 0 ? (
              'No versions yet.'
            ) : prompt.versionsCount === 1 ? (
              '1 version'
            ) : (
              `${prompt.versionsCount} versions`
            )
          ) : (
            <Skeleton className="w-10" />
          )}
        </div>
        <div className="text-xs text-gray-500 pt-3 flex w-1/2 justify-end">
          {prompt ? (
            <div className="text-right">
              <div>
                <FontAwesomeIcon
                  icon={prompt.archived ? faBoxArchive : faClock}
                  className="mr-1 w-3 h-3 text-gray-500  inline"
                />
                <span>
                  {prompt.archived ? 'Archived' : ''} {formatDistanceStrict(prompt.updated, new Date())} ago
                </span>
              </div>
            </div>
          ) : (
            <span className="w-1/2">
              <Skeleton />
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default PromptTile;
